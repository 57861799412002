<template>
  <div v-if="!isPatientDataLoaded">
    <font-awesome-icon icon="spinner" class="fa-md" spin />
          <span class="dark-text">{{ $t('loadingPatientData') }} </span>
  </div>
  <div v-else>
  <div>
  <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
  <div class="br">
    <v-card>
      <v-card-title>
         <h5 class="title text-ibm-plex-sans">{{ $t(title) }}</h5>
         <v-spacer></v-spacer>
         <Button class="button" :disabled="isDisableVideoCall" color="primary" :label="$t('video_call')" v-if="patientObj && patientObj.episodeData.stageData.VideoCallAccess" @click="videoCallClicked"/>
         <Button id="alterAttention" class="button alt-button" color="secondary" :label="$t('change_attention_required')" v-if="patientObj && patientObj.episodeData.stageData.AllowAlterAttentionAccess" @click="alterAttention"/>
      </v-card-title>
      <hr class="ew-hr mlr" />
      <v-card-text class="card-container">
        <v-list class="list-container text-ibm-plex-sans">
          <div v-for="(item, i) in overview" :key="i" >
            <v-list-item class="v-list-item" v-if="i>=0 && i<overview.length/2">
              <v-list-item-title class="key-map" v-text="$t(Object.keys(item)[0]) + ':'" />
              <v-list-item-title class="value-map" v-text="$t(Object.values(item)[0])" />
            </v-list-item>
          </div>
        </v-list>
        <v-list class="list-container text-ibm-plex-sans">
          <div v-for="(item, i) in overview" :key="i" >
            <v-list-item class="v-list-item" v-if="i>=overview.length/2 && i<overview.length">
              <v-list-item-title class="key-map" v-text="$t(Object.keys(item)[0]) + ':'" />
              <v-list-item-title class="value-map" v-text="$t(Object.values(item)[0])" />
            </v-list-item>
          </div>
        </v-list>
        <v-list class="list-container text-ibm-plex-sans" v-if="patientEpisodes.length > 1">
          <Select
            :name="'Patient Episodes'"
            :label="$t('all_episodes')"
            v-model="selectedEpisode"
            :allOptions="patientEpisodes"
            :placeholderText="'Patient Episodes'"
            v-on:change="updatePatientPage"
            :orientation="'v'"
            class="episode-selector"
          />
        </v-list>
      </v-card-text>
    </v-card>
  </div>
  <div class="patient-tabs-container">
    <Tabs :tabs="tabs" colorTheme="light"></Tabs>
  </div>
  </div>
  <Modal v-if="isModalVisible" :headingText="$t(`change_attention_required`)" @close="close()" @click="onSubmitModal()">
      <div slot="body">
        <RadioGroup v-model="updatedAttentionValue" :allOptions="attentionModalValue" :label="''" :orientation="'v'"></RadioGroup>
        <TextArea :label="$t(`additional_notes`) + ':'" v-model="notes" :placeholderText="$t('optional')" :orientation="'v'"></TextArea>
      </div>
    </Modal>
  </div>
</template>
<script>
import Tabs from '../../../shared/components/Tabs.vue'
import Button from '../../../shared/components/Button.vue'
import Select from '../../../shared/components/Select.vue'
import Modal from '../../../shared/components/Modal.vue'
import RadioGroup from '../../../shared/components/RadioGroup.vue'
import Breadcrumbs from '../../../shared/components/Breadcrumbs.vue'
import { mapActions } from 'vuex'
import TextArea from '../../../shared/components/TextArea.vue'

export default {
  data () {
    return {
      tabs: [],
      title: '',
      overview: [],
      patientObj: null,
      isPatientDataLoaded: false,
      countryCode: '',
      patientEpisodes: [],
      selectedEpisode: { Key: this.$route.params.patientId, Value: this.$route.params.patientId },
      isDisableVideoCall: false,
      breadcrumbs: [],
      isModalVisible: false,
      attentionModalValue: [
        { Key: 'HIGH', Value: '_high' },
        { Key: 'MEDIUM', Value: '_medium' },
        { Key: 'LOW', Value: '_low' }
      ],
      updatedAttentionValue: '',
      notes: ''
    }
  },
  name: 'Patient',
  components: {
    Tabs,
    Button,
    Select,
    Breadcrumbs,
    Modal,
    RadioGroup,
    TextArea
  },
  methods: {
    ...mapActions('Patient', ['setPatientId', 'getPatient', 'videoCall', 'alterAttentionUpdate', 'setPatientAttribute', 'getAvailableMerms']),
    async videoCallClicked () {
      this.isDisableVideoCall = true
      await this.videoCall(this.$route.params.patientId)
      this.isDisableVideoCall = false
    },
    alterAttention () {
      this.isModalVisible = true
    },
    close () {
      this.isModalVisible = false
    },
    async onSubmitModal () {
      const params = { patientId: this.$route.params.patientId, attentionRequired: this.updatedAttentionValue, text: this.notes }
      await this.alterAttentionUpdate({ params: params })
      this.isModalVisible = false
      window.open(window.location.href, '_self')
    },
    async updatePatientPage () {
      const pId = this.selectedEpisode.Value
      this.setPatientId(pId)
      this.$router.push(`/dashboard/patient/${pId}`)
      this.isPatientDataLoaded = false
      await this.getAllData(pId)
    },
    async getAllData (patientId) {
      this.setPatientId(patientId)
      var patientObject = await this.getPatient(patientId)
      if (!patientObject) {
        this.$router.push({ name: 'UnifiedPatient' })
      }
      this.setPatientAttribute({
        attributeName: 'stage',
        attributeValue: patientObject.episodeData.stageKey
      })
      this.setPatientAttribute({
        attributeName: 'endDate',
        attributeValue: patientObject.episodeData.endDate
      })
      this.setPatientAttribute({
        attributeName: 'treatmentOutcome',
        attributeValue: patientObject.episodeData.stageData.treatmentOutcome
      })
      if (patientObject.episodeData.stageData.monitoringMethod === 'MERM') {
        const response = await this.getAvailableMerms()
        var availableMermsData = []
        if (response && response.data) {
          availableMermsData = response.data.map(imei => imei.Key)
        }
        this.setPatientAttribute({
          attributeName: 'availableMerms',
          attributeValue: availableMermsData
        })
      }
      this.setPatientAttribute({
        attributeName: 'monitoringMethod',
        attributeValue: patientObject.episodeData.stageData.monitoringMethod
      })

      this.patientObj = patientObject
      this.tabs = []
      for (var t in this.patientObj.episodeData.tabPermissions) {
        if (this.patientObj.episodeData.tabPermissions[t].view) {
          var tempObj = {
            tab_title: this.patientObj.episodeData.tabPermissions[t].tabName,
            tab_content: this.patientObj.episodeData.tabPermissions[t],
            to_link: '/dashboard/patient/' + patientId + '/' + this.patientObj.episodeData.tabPermissions[t].urlFragment,
            alternate_title: this.patientObj.episodeData.tabPermissions[t].tabName
          }
          this.tabs.push(tempObj)
        }
      }
      this.title = this.patientObj.name
      this.overview = this.patientObj.overview
      // this.countryCode = this.patientObj.Patient.CurrentHierarchy.CountryCode
      this.patientEpisodes = this.patientObj.episodes.filter(ele => !!ele).map((ele, id) => ({ Key: ele.toString(), Value: ele.toString() }))
      this.updatedAttentionValue = this.patientObj.episodeData.riskStatus
      this.breadcrumbs = [
        {
          label: 'Dashboard',
          link: '/dashboard/overview',
          class: ''
        },
        {
          label: 'patient_management',
          link: '/dashboard/UnifiedPatient',
          class: ''
        },
        {
          label: this.title,
          link: '',
          class: 'active'
        }
      ]
      this.isPatientDataLoaded = true
    }
  },
  mounted () {
    this.$nextTick(async function () {
      await this.getAllData(this.$route.params.patientId)
    })
  }
}
</script>
<style scoped>
.patient-tabs-container {
  padding: 20px 20px 20px 0px;
}
.title {
  font-size: 20px;
  padding: 15px 10px 15px 24px;
  flex: auto;
  color: var(--text-primary-color);
}
.mlr {
  margin: 0px 24px 0px 24px;
}
v-list {
  padding: 0px !important;
}
.v-list-item {
  min-height: 40px;
  padding: 0 8px;
}
.value-map {
  position: absolute;
  left: 250px;
  font-weight: 600;
  color: var(--text-primary-color);
}
.key-map {
  color: #A0A3BD;
  font-weight: 500;
}
.v-sheet.v-card {
  border-radius: 8px;
  background-color: var(--background-color-primary-light-dark);
  box-shadow: 0px 2px 2px var(--very-light-shadow);

}
.button {
  line-height: 1px;
  border-radius: 100px;
  margin: 5px 20px 5px 0px !important;
  padding: 15px 20px !important;
}
.c-button {
  font-size: 12px !important;
}
.alt-button {
  margin: 0px !important
}
.v-card__title {
  padding: 0px 20px 0px 0px !important;
  font-size: 14px !important;
  line-height: 22px !important;
}
.select-container {
  justify-content: flex-end;
  box-sizing: unset;
  display: flex;
  flex: 1;
}
.card-container {
  display: flex;
}
.list-container {
  flex: 1;
  background-color: var(--background-color-primary-light-dark);
  border-right: 1px solid var(--hr-color);
  margin-left: 20px;
}
.list-container:first-child {
  margin-left: 0;
}
.list-container:last-child {
  border: none;
}
.text-ibm-plex-sans {
  font-family: "IBM Plex Sans", sans-serif;
}
</style>
